@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #121212;
    color: #f5f5f5;
}

h1 {
    text-align: center;
    margin: 20px 0;
    font-size: 2.2rem;
    color: darkred;
}

.impact {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.content-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #1e1e1e;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    text-align: left;
}

.content-header div {
    flex: 1;
    margin: 0 20px;
}

.content-header:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7);
}

.impact-image {
    width: 250px;
    height: 250px;
    border-radius: 15px;
    object-fit: cover;
    margin-right: 20px;
    transition: transform 0.3s ease;
}

.impact-image:hover {
    transform: scale(1.05);
}

.read-more-button {
    padding: 12px 24px;
    background-color: darkred;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-top: 10px;
    align-self: flex-start;
}

.read-more-button:hover {
    background-color: darkred;
    transform: translateY(-3px);
}

.expanded-content {
    padding: 10px;
    margin-top: 10px;
    background-color: #1e1e1e;
    border-radius: 5px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: 0;
}

.expanded-content.show {
    max-height: 1000px;
    opacity: 1;
}

.footer {
    background-color: #282828;
    color: #f5f5f5;
    padding: 40px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin-top: 40px;
    font-size: 1rem;
}

.footer-logo,
.footer-links,
.footer-social,
.footer-contact {
    flex: 1;
    min-width: 200px;
}

.footer-logo img {
    max-width: 150px;
    margin-bottom: 10px;
}

.footer-links ul {
    list-style: none;
    padding: 0;
}

.footer-links ul li {
    margin-bottom: 5px;
}

.footer-links ul li a {
    color: #f5f5f5;
    text-decoration: none;
    transition: color 0.3s ease;
    font-size: 1rem;
}

.footer-links ul li a:hover {
    color: darkred;
}

.footer-social a {
    display: block;
    color: #f5f5f5;
    text-decoration: none;
    margin-bottom: 5px;
    transition: color 0.3s ease;
    font-size: 1rem;
}

.footer-social a:hover {
    color: darkred;
}

.footer-contact p {
    margin: 5px 0;
}

@media (max-width: 768px) {
    .content-header {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .impact-image {
        margin: 0 auto 20px;
    }

    .footer {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-logo,
    .footer-links,
    .footer-social,
    .footer-contact {
        min-width: 100%;
        max-width: 400px;
    }
}