@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
    font-family: 'Times New Roman', Times, serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: black;
    color: #333;
    overflow-x: hidden;
}

header {
    background-color: darkred;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1000;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 10px 15px;
    box-sizing: border-box;
}

.logo {
    height: 40px;
    width: 40px;
    background-color: white;
    border-radius: 50%;
}

.hamburger {
    font-size: 30px;
    color: white;
    cursor: pointer;
    border: none;
    background: transparent;
    display: none;
}

.nav {
    display: flex;
    align-items: center;
    transition: transform 0.3s ease-in-out;
}

.nav ul {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    gap: 20px;
}

.nav ul li {
    position: relative;
}

.header-link,
.nav ul li span {
    color: white;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
}

.header-link:hover,
.nav ul li span:hover {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
}

.dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: darkred;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    text-align: left;
    border-radius: 0 0 5px 5px;
    width: 200%;
    box-sizing: border-box;
}

.nav ul li:hover .dropdown-content {
    display: block;
}

.dropdown-content a {
    color: white;
    cursor: pointer;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s ease;
    box-sizing: border-box;
}

.dropdown-content a:hover {
    background-color: white;
    color: darkred;
}

/* Tablet view */
@media (max-width: 1024px) {
    .hamburger {
        display: block;
    }

    .nav {
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        background-color: darkred;
        flex-direction: column;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        width: 100%;
        box-sizing: border-box;
    }

    .nav.open {
        transform: translateX(0);
    }

    .nav ul {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0;
        width: 100%;
        box-sizing: border-box;
    }

    .nav ul li {
        width: 100%;
    }

    .header-link,
    .nav ul li span {
        width: 100%;
        padding: 10px 20px;
        box-sizing: border-box;
    }

    .dropdown-content {
        position: static;
        width: 100%;
        box-sizing: border-box;
    }

    .dropdown-content a {
        font-size: 14px;
    }
}

/* Mobile view (iPhone XR and smaller devices) */
@media (max-width: 768px) {
    .hamburger {
        display: block;
    }

    .nav {
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        background-color: darkred;
        flex-direction: column;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        width: 100%;
        box-sizing: border-box;
    }

    .nav.open {
        transform: translateX(0);
    }

    .nav ul {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0;
        width: 100%;
        box-sizing: border-box;
    }

    .nav ul li {
        width: 100%;
    }

    .header-link,
    .nav ul li span {
        width: 100%;
        padding: 10px 20px;
        box-sizing: border-box;
    }

    .dropdown-content {
        position: static;
        width: 100%;
        box-sizing: border-box;
    }

    .dropdown-content a {
        font-size: 14px;
    }
}