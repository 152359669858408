@keyframes flipImages {

  0%,
  33.33% {
    background-image: url('../src/images/gal5.jpg');
  }

  33.33%,
  66.66% {
    background-image: url('../src/images/gal15.jpg');
  }

  66.66%,
  100% {
    background-image: url('../src/images/gal8.jpg');
  }
}

.flipImages {
  animation: flipImages 30s linear infinite;
}