@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
    font-family: 'Times New Roman', Times, serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f4f4f4;
    color: #333;
}

.team-page {
    padding: 20px;
    text-align: center;
}

.team-header {
    background-color: darkred;
    color: white;
    padding: 40px 20px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.team-header h1 {
    font-size: 40px;
    margin-bottom: 10px;
    text-align: center;
}

.team-header p {
    font-size: 20px;
    margin: 0;
    text-align: left !important;
    margin-top: 30px;
}

.team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.team-member {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 20px;
    width: 300px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.team-member:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.member-image {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-bottom: 10px;
}

.member-info {
    text-align: left;
}

.member-info h2 {
    text-align: center;
    font-size: 22px;
    margin-bottom: 10px;
}

.member-info h4 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
    color: darkred;
}

.member-info p {
    font-size: 14px;
    margin-bottom: 10px;
    color: black;
    text-align: center;
}

.member-info button {
    /* background-color: darkred; */
    color: white;
    border-radius: 5px;
    margin: auto;
    width: 100px;
    height: 30px;
    cursor: pointer;
    margin-left: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
}

.social-links a {
    display: inline-block;
    margin: 5px;
    color: darkred;
    text-decoration: none;
    transition: color 0.3s ease;
}

.social-links a:hover {
    color: #ffcc00;
}

footer {
    background-color: darkred;
    color: white;
    padding: 40px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footer-logo,
.footer-links,
.footer-social,
.footer-contact {
    flex: 1;
    min-width: 200px;
    margin-bottom: 20px;
}

.footer-logo img {
    width: 100px;
    margin-bottom: 10px;
}

.footer-links ul {
    list-style: none;
    padding: 0;
}

.footer-links li {
    margin: 10px 0;
}

.footer-links a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-links a:hover {
    color: #ffcc00;
}

.footer-social a {
    display: block;
    color: white;
    text-decoration: none;
    margin: 10px 0;
    transition: color 0.3s ease;
}

.footer-social a:hover {
    color: #ffcc00;
}

.footer-contact p {
    margin: 10px 0;
}

@media screen and (max-width: 640px) {
    .team-members {
        flex-direction: column;
        align-items: center;
    }

    .team-member {
        width: 80%;
        max-width: 300px;
    }

    .member-info {
        text-align: left;
    }

    .member-info h2 {
        font-size: 16px;
        text-align: center;
        margin-bottom: 10px;
    }

    .member-info h4 {
        font-size: 14px;
        text-align: center;
        margin-bottom: 10px;
        color: darkred;
    }

    .member-info p {
        font-size: 12px;
        margin-bottom: 10px;
        color: black;
        text-align: center;
    }

    .member-info button {
        background-color: darkred;
        color: white;
        border-radius: 5px;
        margin: auto;
        width: 100px;
        height: 30px;
        cursor: pointer;
        margin-left: 80px !important;
        margin-top: 10px;
        margin-bottom: 10px;
        border: none;
    }


    footer {
        flex-direction: column;
        text-align: center;
    }

    .footer-logo,
    .footer-links,
    .footer-social,
    .footer-contact {
        margin-bottom: 20px;
    }
}